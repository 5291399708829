import {
  CanonicalPageFieldsFragment as DatoCmsCanonicalPageFieldsFragment,
  CanonicalPageFieldsFragment,
  PageMetaFieldsFragment,
  PageMetaFieldsFragment as DatocmsPageMetaFieldsFragment,
  SiteLocale,
} from '../../../../__generated__/datocms.types'
import {PageMeta} from '../types/seo.types'
import {PUBLIC_ROUTES} from '../../../shared/routing/public.routes'
import {validateDatoCmsTypes} from './common.serde'
import {LinkService} from '../../i18n/link.service'
import {fromSiteLocale} from '../site-locale'

export function parsePageMeta(
  linkService: LinkService,
  meta?: PageMetaFieldsFragment | DatocmsPageMetaFieldsFragment,
  locales?: SiteLocale[],
): PageMeta {
  return {
    title: meta?.title,
    description: meta?.description,
    doNotIndex: meta?.doNotIndexPage ?? false,
    canonicalUrl: meta?.canonicalPage ? parseCanonicalPageType(linkService, meta?.canonicalPage) : undefined,
    availableLanguages: locales ? locales.map(fromSiteLocale) : undefined,
  }
}

export function parseCanonicalPageType(
  linkService: LinkService,
  entry: CanonicalPageFieldsFragment | DatoCmsCanonicalPageFieldsFragment,
): string | undefined {
  const page = validateDatoCmsTypes(entry)

  switch (page.__typename) {
    case 'ArticleModelRecord':
      return linkService.localizedPathTo(PUBLIC_ROUTES.ARTICLE_PAGE).replace(':pageSlug', page.slug)
    case 'TopicOverviewPageModelRecord':
      return linkService.localizedPathTo(PUBLIC_ROUTES.TOPIC_OVERVIEW_PAGE).replace(':pageSlug', page.slug)
    case 'ServiceOverviewPageModelRecord':
      return linkService.localizedPathTo(PUBLIC_ROUTES.SERVICE_OVERVIEW_PAGE).replace(':pageSlug', page.slug)
    case 'OfferingDetailPageModelRecord':
      return linkService.localizedPathTo(PUBLIC_ROUTES.OFFERING_DETAIL_PAGE).replace(':pageSlug', page.slug)
    case 'IndustryOverviewPageModelRecord':
      return linkService.localizedPathTo(PUBLIC_ROUTES.INDUSTRY_OVERVIEW_PAGE).replace(':pageSlug', page.slug)
    case 'EmergencyLandingPageModelRecord':
      return linkService.localizedPathTo(PUBLIC_ROUTES.EMERGENCY_LANDING_PAGE).replace(':pageSlug', page.slug)
    case 'CategoryLandingPageModelRecord':
      return linkService.localizedPathTo(PUBLIC_ROUTES.CATEGORY_LANDING_PAGE).replace(':pageSlug', page.slug)
    default:
      return undefined
  }
}
